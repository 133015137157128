.team_wraper h4 {
  color: #1a2a79;
  text-align: center;
}
.team_wraper h5 {
  color: #439037;
  text-align: center;
}
.team_wraper p {
  margin-top: 27px;
  text-align: center;
  margin-bottom: 90px;
}
.member-detail h6 {
  color: #1a2a79;
  font-size: 20px;
}
.member-detail p {
  text-align: center;
  margin: 0;
  line-height: 21px;
  margin-top: 9px;
}
.member-detail {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 0 16px #1a2a7924;
  max-width: 216px;
  min-height: 102px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -24px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
}
.overlay-position {
  position: relative;
  z-index: -99;
}
.first_member {
  max-width: 285px;
  width: 100%;
  min-height: 518px;
  margin-right: 40px;
}
.team-members {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 95px;
}
.mt-102 {
  margin-top: 93px;
}
.position-mt-102 {
  position: relative;
  top: 102px;
}
.mr-0 {
  margin-right: 0;
}
@media (max-width: 1680px) {
  .our_team {
    margin-top: 95px;
  }
  .first_member {
    max-width: 231px;
  }
}
@media (max-width: 1480px) {
  .first_member {
    max-width: 235px;
  }
}
@media (max-width: 1399px) {
  .member-detail {
    max-width: 197px;
    min-height: 85px;
  }
  .team-members {
    margin-bottom: 0;
  }
}
@media (max-width: 1199px) {
  .first_member {
    min-height: 379px;
  }
}
@media (max-width: 991px) {
  .position-mt-102 {
    top: 0;
  }
  .first_member {
    min-height: 360px;
  }
  .our_team {
    margin-top: 95px;
  }
  .team-members {
    justify-content: space-evenly;
    margin-bottom: 40px;
  }
  .first_member {
    margin-right: 20px;
  }
  .mt-102 {
    margin-top: 50px;
  }
  .our_team {
    margin-top: 34px;
  }
  .team_wraper > p {
    margin-bottom: 62px;
  }
}
@media (max-width: 767px) {
  .first_member {
    min-height: 380px;
    margin-right: 0;
  }
  .our_team {
    margin-top: 5px;
  }
}
@media (max-width: 575px) {
  .mt-102 {
    margin-top: 18px;
  }
  .team-members {
    margin-bottom: 15px;
  }
  .team_wraper > p {
    margin-bottom: 40px;
    margin-top: 13px;
  }
}
