.btn-holder {
  padding-top: 30px;
}
section.improved-crm-section {
  background: transparent
    linear-gradient(224deg, #fff 0, #f7f7fe 36%, #f4f4fd 100%) 0 0 no-repeat
    padding-box;
  padding-top: 200px;
  margin-top: -200px;
  padding-bottom: 100px;
}
.services-detail.row {
  padding: 20px 0;
}
.services-card {
  border-radius: 8px;
  padding: 25px;
  text-align: center;
  min-height: 237px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
}
.services-card h6 {
  color: #3d3d3d;
  font-size: 20px;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  section.improved-crm-section {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 0;
  }
}
