.ceo-section {
  background: #f5f5f5 0 0 no-repeat padding-box;
  margin-bottom: 100px;
}
.founder-details p.ceo {
  color: #1a2a79;
  margin-top: 30px;
  font-weight: 500;
}
.founder-details p {
  max-width: 705px;
  width: 100%;
  color: #3d3d3d;
}
.founder-details h3 {
  color: #1a2a79;
  font-size: 43px;
  font-weight: 400;
  margin-top: 41px;
}
.founder-details > img {
  margin-bottom: 25px;
}
.ceo-message-wraper {
  display: flex;
  align-items: center;
  min-height: 536px;
}
.founder-details {
  margin-left: 182px;
}
.ceo-founder img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ceo-founder {
  width: 705px;
  height: 536px;
}
@media (max-width: 1880px) {
  .founder-details {
    margin-left: 30px;
  }
}
@media (max-width: 1399px) {
  .ceo-founder {
    width: 654px;
    height: 445px;
  }
  .ceo-message-wraper {
    min-height: 442px;
  }
  .founder-details h3 {
    font-size: 34px;
    margin-top: 21px;
  }
}
@media (max-width: 1280px) {
  .ceo-message-wraper {
    margin-top: 100px;
  }
}
@media (max-width: 991px) {
  .ceo-message-wraper {
    flex-direction: column;
    margin-top: 50px;
  }
  .founder-details {
    text-align: center;
    margin-top: 45px;
  }
  .founder-details h3 {
    font-size: 37px;
    margin-top: 16px;
    margin-bottom: 29px;
  }
  .ceo-founder {
    order: 2;
    height: auto;
  }
  .ceo-section {
    margin-bottom: 48px;
  }
}
@media (max-width: 767px) {
  .founder-details h3 {
    font-size: 29px;
  }
  .ceo-founder img {
    width: 60%;
    margin: 0 auto;
  }
  .ceo-founder {
    display: flex;
  }
  .founder-details > img {
    margin-bottom: 25px;
    width: 70px;
  }
  .ceo-message-wraper {
    margin-top: 30px;
  }
  .ceo-message-wraper {
    margin-top: 0;
  }
}
@media (max-width: 575px) {
  .ceo-founder img {
    width: 80%;
  }
  .founder-details > img {
    margin-bottom: 25px;
    width: 50px;
  }
  .founder-details h3 {
    font-size: 27px;
    margin-top: 8px;
    margin-bottom: 7px;
  }
  .compensate-accordian .accordion-button p {
    font-size: 12px;
    width: 100px;
  }
  .compensate-accordian .accordion-button {
    padding: 8px;
  }
}
@media (max-width: 400px) {
  .ceo-founder img {
    width: 45%;
  }
}
