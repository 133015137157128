.form_image {
  position: relative;
  background-image: url(../../../../assets/images/contact-img.png);
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 26px;
  background-position: right;
}
.thanks_image {
  position: relative;
  background-image: url(../../../../assets/images/contact-img.png);
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 26px;
  background-position: right;
}
.form_content label {
  font-size: 20px;
  color: #1a2a79;
  font-weight: 300;
  margin-bottom: 10px;
  line-height: 26px;
  font-weight: 600;
}
.form_content.step4-form-content label {
  font-size: 15px;
  line-height: 22px;
}
.form_content .form-control {
  background-color: #fff;
  box-shadow: 0 0 10px #0000001a;
  border-radius: 10px;
  border: none;
  padding: 18px 30px;
  color: #888;
  font-size: 18px;
}
.qoute__form {
  padding: 20px 75px 20px 20px;
  box-shadow: 0 0 20px #00000012;
  border-radius: 30px;
  opacity: 1;
}
.form_content {
  padding-left: 40px;
}
.small-heading {
  font-weight: 500;
  line-height: 20px;
}
.form_image img {
  border-radius: 27px;
  width: 100%;
}
.thanks_image img {
  border-radius: 27px;
  width: 100%;
}
.form_content select {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px #0000001a;
  border-radius: 10px;
  border: none;
  padding: 18px 30px;
  color: #1a2a79;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 300;
  appearance: none;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 30px);
  background-position-y: calc(100% - 28px);
  font-weight: 600;
}
.Submitting_form {
  color: #161616;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 300 !important;
  margin-left: 27px;
}
.terms_condition {
  margin-top: 40px;
}
.qoute__form {
  margin: 70px 0 120px 0;
}
.qoute__form.form_content.thanku-form {
  padding: 63px 57px;
  margin-top: 0;
  border: 3px solid #83a9c4;
}
@media only screen and (max-width: 1600px) {
  .form_content label {
    font-size: 16px;
    line-height: 21px;
  }
  .form_content .form-control,
  .form_content select {
    padding: 15px 13px;
    font-size: 16px;
  }
  .form_content select {
    background-position-x: calc(100% - 19px);
    background-position-y: calc(100% - 20px);
  }
  .Submitting_form {
    font-size: 16px;
    line-height: 22px;
  }
  .form_content.step4-form-content label {
    font-size: 14px;
    line-height: 17px;
  }
  .qoute__form.form_content.thanku-form {
    padding: 42px;
  }
}
@media only screen and (max-width: 1367px) {
  .form_content label {
    font-size: 15px;
    line-height: 18px;
  }
  .form_content .form-control,
  .form_content select {
    padding: 12px 13px;
    font-size: 15px;
  }
  .form_content select {
    background-position-x: calc(100% - 14px);
    background-position-y: calc(100% - 18px);
  }
  .Submitting_form {
    font-size: 14px;
    line-height: 18px;
  }
  .terms_condition {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 1199px) {
  .form_content label {
    font-size: 14px;
  }
  .form_content .form-control,
  .form_content select {
    padding: 10px 10px;
    font-size: 14px;
  }
  .form_content select {
    background-position-y: calc(100% - 14px);
  }
  .Submitting_form {
    font-size: 13px;
    line-height: 16px;
  }
  .terms_condition {
    margin-top: 16px;
  }
  .form_image {
    height: 50vh;
  }
  .thanks_image {
    height: 50vh;
  }
  .qoute__form {
    padding: 0;
    margin: 20px 0 100px 0;
  }
  .qoute__form {
    padding: 0;
    margin: 20px 0 100px 0;
    max-width: 100%;
    margin: 50px auto;
    padding: 25px;
  }
  .form_image {
    display: none;
  }
  .form_content {
    padding-left: 0;
  }
}
@media only screen and (max-width: 992px) {
  .thanks_image {
    height: 30vh;
  }
}
@media only screen and (max-width: 767px) {
  .qoute__form.form_content.thanku-form {
    padding: 25px;
  }
}
@media only screen and (max-width: 575px) {
  .form_content .form-control,
  .form_content label,
  .form_content select {
    font-size: 12px;
  }
}
