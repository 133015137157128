.signup-baner{background-image:url(../../../../assets/images/signup-banner.png);min-height:100vh;background-repeat:no-repeat;background-position:right;background-size:100% 100%;position:relative;padding:267px 0 350px}
@media only screen and (max-width:1600px){
.signup-baner{min-height:90vh;padding:210px 0 171px}
}
@media only screen and (max-width:1400px){
.signup-baner{min-height:87vh;padding:200px 0 171px}
}
@media only screen and (max-width:1199px){
.signup-baner{background-size:cover;background-position:top}
}
@media only screen and (max-width:992px){
.signup-baner{min-height:66vh;padding:125px 0 144px}
}
@media only screen and (max-width:768px){
.signup-baner{background-image:url(../../../../assets/images/signup-banner-bg.png);min-height:66vh;padding:125px 0 144px}
}