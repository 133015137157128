.margin-progress {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.margin-set-thankyou-page {
  margin-bottom: 100px;
}
.floating-div {
  position: fixed;
  bottom: 150px;
  right: 510px;
  width: 50px;
  height: 50px;
  background-color: #3498db;
  color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
}
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
/* .tooltip:hover .tooltiptext {
  visibility: visible;
} */
.tooltip-wrapper {
  position: relative;
  text-transform: initial;
}
.main-wrapper-tooltip {
  margin-top: 0;
  position: relative;
}
.main-wrapper-tooltip p {
  text-transform: initial;
}
.tooltips:hover span.tooltiptext {
  display: block;
  filter: alpha(opacity=100);
  top: -180px;
  left: 0px;
  z-index: 99;
}

.tooltips {
  color: #1e368a;
  text-decoration: underline;
  margin-left: 4px;
}

.tooltip-wrapper span.tooltiptext {
  background: none repeat scroll 0 0 #ecf4eb;
  color: black;
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 16px 15px;
  width: 347px;
  top: unset;
  left: 0px;
  margin-left: 0;
  display: none;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  z-index: 2;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  bottom: 50px;
}

.tooltip-wrapper span.tooltiptext:after {
  content: "";
  background: #ecf4eb;
  left: 21px;
  bottom: -40px;
  position: absolute;
  width: 40px;
  height: 40px;
  clip-path: polygon(0 0, 100% 0, 51% 56%);
}
.tooltip-wrapper > span.tooltips:hover {
  color: #1e368a;
}
.tooltip-wrapper:hover span.tooltiptext {
  opacity: 1;
  display: block;
}

@media (max-width: 575px) {
  .tooltip-wrapper span.tooltiptext {
    width: 286px;
    left: -101px;
    padding: 7px;
  }
  .agreement-footer img {
    width: 150px;
    object-fit: contain;
  }
}

@media (max-width: 400px) {
  .tooltip-wrapper span.tooltiptext {
    width: 260px;
    left: -101px;
  }
  .qoute__form {
    padding: 15px;
  }
}
