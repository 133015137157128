.baner-section {
  background-image: url(../../../../assets/images/banner-image-new.png);
  min-height: 144vh;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 100% 100%;
  position: relative;
  padding: 267px 0 350px;
}
ul.top-services li {
  list-style-type: none;
  position: relative;
  padding-left: 37px;
}
ul.top-services li::before {
  position: absolute;
  content: "";
  left: 0;
  width: 20px;
  height: 20px;
  background-image: url(../../../../assets/images/list-dot.svg);
  background-size: contain;
  top: 30px;
}
.content--row {
  padding-top: 50px;
  position: relative;
}
.baner-content {
  max-width: 740px;
}
.baner-btn {
  margin-top: 70px;
}
.baner-content h3 {
  font-size: 45px;
}
.baner-content h5 {
  font-weight: 400;
}
.baner-content p {
  font-size: 22px;
  line-height: 30px;
  padding-top: 20px;
  font-weight: 300;
}
.mobile-banner {
  display: none;
}
@media only screen and (max-width: 1600px) {
  .baner-content h3 {
    font-size: 40px;
  }
  .baner-content p {
    font-size: 18px;
    line-height: 28px;
    padding-top: 15px;
  }
  ul.top-services li::before {
    width: 16px;
    height: 16px;
    top: 23px;
  }
}
@media only screen and (max-width: 1500px) {
  .baner-content h3 {
    font-size: 34px;
  }
  .baner-section {
    min-height: 120vh;
    padding: 230px 0 350px;
  }
}
@media only screen and (max-width: 1400px) {
  .baner-section {
    background-size: cover;
    background-position: top;
  }
}
@media only screen and (max-width: 1199px) {
  .baner-section {
    padding: 188px 0 350px;
  }
  .baner-content h3 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 992px) {
  .baner-content h3 {
    font-size: 26px;
    line-height: 35px;
  }
  .baner-btn {
    margin-top: 57px;
  }
}
@media only screen and (max-width: 767px) {
  .mobile-banner {
    display: block;
  }
  .baner-section {
    background-image: unset;
    background-color: #f2fafe;
    text-align: center;
    padding: 133px 0 100px;
  }
  ul.top-services li::before {
    display: none;
  }
  ul.top-services li {
    padding-left: 0;
  }
  .baner-img-wraper img {
    width: 70%;
    margin-top: 50px;
    margin-left: auto;
  }
}
@media only screen and (max-width: 767px) {
  .baner-btn .green-borderd-btn,
  .baner-btn .theme-btn {
    width: 80%;
  }
  .baner-btn {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .baner-content p {
    font-size: 15px;
    line-height: 24px;
    padding-top: 9px;
  }
  .baner-content h3 {
    font-size: 18px;
    line-height: 35px;
  }
}
@media only screen and (max-width: 375px) {
  .baner-content h1 {
    font-size: 25px;
    line-height: 42px;
  }
  .baner-content h3 {
    font-size: 16px;
    line-height: 27px;
  }
  .baner-section {
    padding: 100px 0 60px;
    min-height: 80vh;
  }
}
