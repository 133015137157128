.progressbar_wrap {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  width: 100%;
  justify-content: center;
}
.progress_bar {
  display: flex;
  justify-content: space-between;
  max-width: 420px;
  flex: 0 0 420px;
  position: relative;
  overflow: hidden;
}
.progress_bar .number.active {
  background: #1b2f79;
  color: #fff;
}
.progress_bar .number {
  height: 67px;
  width: 67px;
  background: #ededed;
  border: 2px solid #ededed;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
  font-weight: 700;
  color: #b7b7b7;
}
.progress_bar .progress_line {
  position: absolute;
  left: 65px;
  top: 50%;
  transform: translateY(-50%);
  height: 11px;
  width: 100%;
  border-radius: 0 50px 50px 0;
  background: #ededed;
  z-index: -1;
}
.progress_bar .progress_line span {
  display: block;
  height: calc(100% - 4px);
  background: #1b2f79;
  border-radius: 0 50px 50px 0;
  position: relative;
  top: 2px;
  font-size: 0;
}
.progress_bar .number svg {
  width: 16px;
  margin-top: -6px;
}
h5.step_number {
  font-size: 18px;
  color: #000;
  font-weight: 400;
  margin-bottom: 15px;
}
@media only screen and (max-width: 575px) {
  .progress_bar .number {
    height: 51px;
    width: 51px;
    font-size: 20px;
  }
  .progress_bar {
    max-width: 318px;
    flex: 0 0 318px;
  }
  .progress_bar .progress_line {
    left: 50px;
  }
}
