@font-face {
  font-family: Outfit;
  src: url(./assets/fonts/Outfit-ExtraLight.woff2) format("woff2"),
    url(./assets/fonts/Outfit-ExtraLight.woff) format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Outfit;
  src: url(./assets/fonts/Outfit-ExtraBold.woff2) format("woff2"),
    url(./assets/fonts/Outfit-ExtraBold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Outfit;
  src: url(./assets/fonts/Outfit-Black.woff2) format("woff2"),
    url(./assets/fonts/Outfit-Black.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Outfit;
  src: url(./assets/fonts/Outfit-Light.woff2) format("woff2"),
    url(./assets/fonts/Outfit-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Outfit;
  src: url(./assets/fonts/Outfit-Medium.woff2) format("woff2"),
    url(./assets/fonts/Outfit-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Outfit;
  src: url(./assets/fonts/Outfit-Regular.woff2) format("woff2"),
    url(./assets/fonts/Outfit-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Outfit;
  src: url(./assets/fonts/Outfit-SemiBold.woff2) format("woff2"),
    url(./assets/fonts/Outfit-SemiBold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Outfit;
  src: url(./assets/fonts/Outfit-Thin.woff2) format("woff2"),
    url(./assets/fonts/Outfit-Thin.woff) format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
body {
  font-family: Outfit;
  font-weight: 400;
  font-size: 18px;
  color: #000;
  min-height: 100vh;
}
.App-wraper {
  overflow: hidden;
}
.homepg-wraper {
  overflow: hidden;
}
.custom-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 7.5%;
}
.gray-clr {
  color: #4b4b4b !important;
}
.gray-light {
  color: #d0d0d0;
}
.theme-clr {
  color: #1a2a79;
}
.green-clr {
  color: #439037;
}
.light-greenbg {
  background: #e4eeef;
}
.pink-bg {
  background: #f7dedc;
}
.lemon-bg {
  background: #f2f2d8;
}
.solid-greenbg {
  background: #dcedd9;
}
ul {
  padding-left: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin-bottom: 0;
}
.btn,
a {
  -o-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all 0.3s ease-in-out;
}
.theme-btn {
  background: #1a2a79;
  padding: 18px 25px;
  border-radius: 14px;
  text-transform: capitalize;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #fff;
}
.theme-btn:hover {
  border-color: #1a2a79;
  color: #1a2a79;
}
.green-borderd-btn {
  color: #fff;
  border-radius: 14px;
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 600;
  padding: 15px 25px;
  background: #439037 0 0 no-repeat padding-box;
  border-radius: 14px;
}
.green-borderd-btn:hover {
  border: 1px solid #439037;
  color: #439037;
  background: #fff;
}
.padding-top {
  padding-top: 150px;
}
.padding-bottom {
  padding-bottom: 150px;
}
h1 {
  font-size: 84px;
  line-height: 100px;
  font-weight: 700;
  letter-spacing: 6.89px;
}
h2 {
  font-size: 68px;
  line-height: 86px;
  font-weight: 700;
}
h3 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
}
h4 {
  font-size: 35px;
  font-weight: 700;
  line-height: 43px;
}
h5 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}
h6 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}
p {
  font-size: 18px;
  line-height: 30px;
}
.custom-control.overflow-checkbox .overflow-control-input {
  display: none;
}
.custom-control.overflow-checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.custom-control.overflow-checkbox .overflow-control-input {
  display: none;
}
.custom-control.overflow-checkbox .overflow-control-indicator {
  border-radius: 3px;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #1a2a79;
  cursor: pointer;
}
.custom-control.overflow-checkbox .overflow-control-indicator::after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  transition: 0.3s;
  transform: rotateZ(90deg) scale(0);
  width: 10px;
  border-bottom: 4px solid #1a2a79;
  border-right: 4px solid #1a2a79;
  border-radius: 3px;
  top: -2px;
  left: 2px;
}
.custom-control.overflow-checkbox .overflow-control-indicator::before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  transition: 0.3s;
  width: 10px;
  border-right: 7px solid #fff;
  border-radius: 3px;
  transform: rotateZ(45deg) scale(1);
  top: -4px;
  left: 5px;
  opacity: 0;
}
.custom-control.overflow-checkbox
  .overflow-control-input:checked
  ~ .overflow-control-indicator::after {
  transform: rotateZ(45deg) scale(1);
  top: -6px;
  left: 5px;
}
.custom-control.overflow-checkbox
  .overflow-control-input:checked
  ~ .overflow-control-indicator::before {
  opacity: 1;
}
.custom-control.overflow-checkbox
  .overflow-control-input:disabled
  ~ .overflow-control-indicator {
  opacity: 0.5;
  border: 2px solid #1a2a79;
}
.custom-control.overflow-checkbox
  .overflow-control-input:disabled
  ~ .overflow-control-indicator:after {
  border-bottom: 4px solid #1a2a79;
  border-right: 4px solid #1a2a79;
}
.form-control:focus {
  box-shadow: 0 0 0 0.1rem rgba(67, 144, 55);
}
::-webkit-scrollbar {
  width: 10px;
  height: 9px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #1a2a79;
  border-radius: 10px;
}
@media only screen and (max-width: 1600px) {
  .padding-top {
    padding-top: 100px;
  }
  .padding-bottom {
    padding-bottom: 100px;
  }
  p {
    font-size: 16px;
    line-height: 26px;
  }
  h1 {
    font-size: 72px;
  }
  h3 {
    font-size: 50px;
    line-height: 55px;
  }
  h4 {
    font-size: 30px;
    line-height: 36px;
  }
  h5 {
    font-size: 31px;
    line-height: 38px;
  }
  h6 {
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
  }
  .green-borderd-btn,
  .theme-btn {
    padding: 8px 20px;
    font-size: 20px;
    height: unset;
  }
}
.red_staric {
  color: #dc3545;
  padding-left: 5px;
  font-size: 14px;
}
@media only screen and (max-width: 1500px) {
  .theme-btn {
    padding: 7px 23px;
    font-size: 18px;
  }
  .custom-container {
    padding: 0 6.7%;
  }
  .green-borderd-btn,
  .theme-btn {
    font-size: 16px;
  }
  h1 {
    font-size: 60px;
    line-height: 70px;
  }
  h2 {
    font-size: 55px;
    line-height: 56px;
  }
  p {
    font-size: 15px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 1399px) {
  .custom-container {
    padding: 0 5.5%;
  }
  h2 {
    font-size: 39px;
  }
  h3 {
    font-size: 33px;
    line-height: 50px;
  }
  h4 {
    font-size: 28px;
    line-height: 34px;
  }
  h5 {
    font-size: 27px;
    line-height: 35px;
  }
}
@media only screen and (max-width: 1199px) {
  .custom-container {
    padding: 0 4%;
  }
  .padding-bottom {
    padding-bottom: 70px;
  }
  .padding-top {
    padding-top: 70px;
  }
  h1 {
    font-size: 53px;
    line-height: 65px;
  }
  h2 {
    font-size: 35px;
    line-height: 57px;
  }
  h3 {
    font-size: 29px;
    line-height: 50px;
  }
  h4 {
    font-size: 27px;
    line-height: 34px;
  }
  p {
    font-size: 14px;
    line-height: 18px;
  }
  h6 {
    font-size: 20px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 992px) {
  .lead-img-container img {
    width: 80%;
    margin: 0 auto;
    display: flex;
  }
  h1 {
    font-size: 45px;
    line-height: 60px;
  }
  h2 {
    font-size: 33px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 767px) {
  h4 {
    font-size: 23px;
    line-height: 29px;
  }
  h3 {
    font-size: 31px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 30px;
    line-height: 42px;
  }
  h2 {
    font-size: 26px;
    line-height: 35px;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
  }
  .custom-container {
    padding: 0 6%;
  }
  h5 {
    font-size: 20px;
    line-height: 29px;
  }
}
@media only screen and (max-width: 450px) {
  .red_staric {
    padding-left: 0;
    font-size: 13px;
    margin-top: 2px;
  }
}
