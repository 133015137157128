.agreement-header {
  display: flex;
  justify-content: center;
  height: 101px;
  margin-bottom: 20px;
}
.agreement-footer {
  display: flex;
  justify-content: center;
  height: 60px;
  margin: 5px;
}
.agreement-setting {
  padding: 20px;
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}
.agreement-listing {
  padding: 0 0 4px 10px;
}
.alpha-listing {
  list-style-type: upper-alpha;
}
.btn.small-btn {
  padding: 10px 16px;
  border-radius: 10px;
  font-size: 16px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 40px;
  line-height: 20px;
}
.fix-ed-butt-on-scro-ll {
  position: absolute;
  right: 13%;
  opacity: 0.5;
  background-color: #1a2a79;
}
.fix-ed-butt-on-scro-ll-btm {
  position: absolute;
  right: 13%;
  bottom: 13%;
  opacity: 0.5;
  background-color: #1a2a79;
}
.fix-ed-butt-on-scro-ll:hover {
  background-color: #1a2a79;
  opacity: 1;
}
.fix-ed-butt-on-scro-ll-btm:hover {
  background-color: #1a2a79;
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .agreement-header {
    height: unset;
  }
  .agreement-header img {
    width: 150px;
  }
  .fix-ed-butt-on-scro-ll {
    right: 8%;
  }
  .fix-ed-butt-on-scro-ll-btm {
    right: 8%;
  }
  .btn.small-btn {
    width: 100%;
  }
  .agreement-setting ol,
  .agreement-setting ul {
    padding-left: 0;
  }
  .agreement-listing {
    font-size: 13px;
  }
}
