.site-footer {
  background-image: url(../../../assets/images/footer-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.get-started.row {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: -1px -15px 21px #1c0af914;
  border-radius: 40px;
  color: #070202;
  max-width: 1100px;
  margin: 0 auto;
  padding: 60px;
  display: flex;
  align-items: center;
}
.footer-content-row.row {
  margin-top: 85px;
}
.footer-content {
  padding-top: 42px;
  font-size: 16px;
  margin-right: 30px;
}
.get-start-content h4 {
  max-width: 400px;
  line-height: 40px;
}
.get-start-content p {
  margin-top: 15px;
}
.footer-newsletter .field-wrapper {
  display: flex;
  margin-top: 40px;
}
.footer-newsletter {
  margin-bottom: 60px;
}
.footer-newsletter h5 {
  font-size: 24px;
  line-height: 26px;
}
.field-wrapper .form-control {
  background: #fff;
  border-radius: 10px;
  font-size: 18px;
  padding: 10px 17px 10px 10px;
  height: 60px;
  border: none;
  color: #273770 !important;
  border-radius: 8px;
  font-weight: 500;
  color: #d0d0d0;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.field-wrapper::placeholder {
  color: #273770 !important;
  opacity: 0.57;
}
button.green-btn {
  background: #439037;
  border-radius: 0 8px 8px 0;
  margin-left: -9px;
}
button.green-btn:hover {
  border-color: #fff;
  background-color: #439037;
}
.form-control:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0;
}
textarea > .form-control {
  border: none;
}
.contact-list .footer-links li a {
  color: #fff;
}
.contact-list .footer-links li {
  margin-bottom: 15px;
}
.field-wrapper .theme-btn {
  margin-left: -25px;
}
.footer-logo {
  margin-top: -28px;
  width: 240px;
}
.footer-info {
  padding-right: 40px;
}
.footer-info p {
  margin-top: 45px;
  margin-bottom: 50px;
}
.follow-us ul {
  margin-top: 38px;
  display: flex;
  align-items: center;
}
.follow-us ul li a {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin-right: 15px;
  font-size: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.store-logos .image:last-child {
  margin: 0;
}
.follow-us ul li:last-child a {
  margin: 0;
}
.follow-us ul li .facebook {
  color: #6380c5;
}
.follow-us ul li .facebook:hover {
  background: #6380c5;
  color: #fff;
}
.follow-us ul li .linkedin {
  color: #087bb7;
}
.follow-us ul li .linkedin:hover {
  background: #087bb7;
  color: #fff;
}
.follow-us ul li .twitter {
  color: #03a9f4;
}
.follow-us ul li .twitter:hover {
  color: #fff;
  background: #03a9f4;
}
.follow-us ul li .pinterest {
  color: #cb1f24;
}
.follow-us ul li .pinterest:hover {
  color: #fff;
  background: #cb1f24;
}
.follow-us ul li .youtube {
  color: red;
}
.follow-us ul li .youtube:hover {
  color: #fff;
  background: red;
}
.footer-links .opacity {
  opacity: 0;
}
.footer-links h5 {
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 26px;
}
.footer-links li {
  margin-bottom: 20px;
  font-size: 16px;
}
.footer-links li a {
  color: #fff;
  text-decoration: none;
}
.footer-links li a:hover {
  color: #439037;
}
.drop-down__menu li:hover,
li.menu-item-has-children a:hover {
  color: #439037;
}
.footer-links .contact-info li {
  font-size: 16px;
}
.footer-links .contact-info li a {
  display: flex;
}
.footer-links .contact-info li .icon {
  margin-right: 15px;
  margin-top: 3px;
}
.copyrights {
  padding: 20px 0;
}
.copyrights p {
  text-align: center;
}
.bottom-footer {
  margin-top: 50px;
  background: #202b63;
}
@media only screen and (max-width: 1600px) {
  .footer-links h5 {
    margin-bottom: 28px;
    font-size: 24px;
  }
  .footer-links li {
    margin-bottom: 7px;
    font-size: 14px;
  }
  .footer-links .contact-info li {
    font-size: 14px;
  }
  .field-wrapper .form-control {
    font-size: 14px;
  }
  .footer-content {
    padding-top: 30px;
    font-size: 14px;
    margin-right: 0;
  }
  .footer-logo {
    margin-top: -16px;
    width: 200px;
  }
  .get-started.row {
    max-width: 856px;
    padding: 40px;
  }
}
@media only screen and (max-width: 1399px) {
  .footer-links h5 {
    margin-bottom: 22px;
    font-size: 18px;
  }
  .field-wrapper .form-control {
    height: 42px;
  }
}
@media only screen and (max-width: 1199px) {
  .padding-100 {
    padding: 40px 0;
  }
}
@media only screen and (max-width: 991px) {
  .get-start-content h4 {
    max-width: 100%;
    line-height: 25px;
  }
  .footer-logo {
    width: 280px;
  }
  .site-footer {
    padding-top: 0;
    text-align: center;
    background-size: cover;
    background-position: center;
  }
  .footer-content-row.row {
    margin-top: 45px;
  }
  .footer-logo {
    margin: 0 auto;
  }
  .contact-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .list-unstyled {
    padding-left: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .bottom-footer {
    margin-top: 20px;
  }
  .footer-newsletter {
    margin-bottom: 0;
  }
  .footer-links li {
    margin-bottom: 8px;
    font-size: 16px;
    margin-right: 15px;
  }
  .field-wrapper .form-control {
    font-size: 15px;
  }
  .p-text-24 {
    font-size: 16px;
    line-height: 22px;
  }
  .footer-links .contact-info li {
    font-size: 16px;
  }
  .footer-links h5 {
    margin-bottom: 13px;
    font-size: 17px;
  }
  .follow-us ul {
    margin-top: 15px;
  }
  .footer-newsletter .field-wrapper {
    margin-top: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-newsletter h5 {
    font-size: 21px;
    line-height: 53px;
  }
  .footer-logo {
    width: 220px;
  }
  .footer-newsletter .field-wrapper {
    margin-top: 0;
  }
  .footer-links .contact-info li {
    font-size: 14px;
    margin-bottom: 7px;
  }
  .field-wrapper .form-control {
    height: 40px;
  }
  .copyrights {
    padding: 10px 0;
  }
}
@media only screen and (max-width: 575px) {
  .footer-links h5 {
    margin-bottom: 5px;
    font-size: 15px;
  }
  .get-started.row {
    padding: 20px;
  }
  .follow-us ul li a {
    width: 24px;
    height: 24px;
    font-size: 12px;
  }
  .footer-logo {
    width: 158px;
  }
  .footer-content {
    padding-top: 9px;
    font-size: 13px;
  }
  .footer-links li {
    margin-bottom: 5px;
    font-size: 14px;
    margin-right: 11px;
  }
}
