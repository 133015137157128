.Partner-Benefits h5 {
  color: #439037;
}
.Partner-Benefits h4 {
  color: #1a2a79;
}
.Partner-Benefits p {
  max-width: 604px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 40px;
}

.services-wraper {
  display: flex;
}
.Partner-Benefits {
  margin-top: 100px;
  margin-left: 170px;
}
.Partner-Benefits.ml-0 {
  margin-left: 18px;
  margin-right: 92px;
  margin-top: 51px;
}
/* .services-wraper.mt-102{
    margin-top: 102px;
} */
.Partner-Benefits.affordable_connectivity p {
  max-width: 614px;
}

.our-services {
  position: relative;
  margin-bottom: 0px;
  margin-top: 104px;
}
.our-services::after {
  content: "";
  position: absolute;
  background-image: url("../../../../assets/images/double-lines.png");
  width: 595px;
  height: 348px;
  bottom: -116px;
  right: -70px;
  z-index: -1;
}
.Partner-Benefits.affordable_connectivity button {
  margin-top: 22px;
}
.our_team {
  margin-top: 164px;
}

/* responsiveness */


@media(max-width:1740px){
    .Partner-Benefits {
        margin-left: 70px;
    }
}


@media (max-width: 1399px) {
  .Partner-Benefits {
    margin-left: 75px;
    margin-top: 54px;
  }
  .our-services {
    margin-top: 62px;
  }
  .services-benfits .img-fluid{
    height: 100%;
  }
  .services-benfits {
    height: 424px;

  }
}
@media(max-width:1199px){
    .our-services::after {
        bottom: -29px;
    }
}
@media (max-width: 991px) {
  .services-card h6 {
    font-size: 18px;
}
  .our-services::after {
    display: none;
  }

  .services-wraper {
    flex-wrap: wrap;
    justify-content: center;

  }
  .Partner-Benefits {
    margin-left: 0px;
    margin-top: 36px;
  }
  .Partner-Benefits.affordable_connectivity button {
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .Partner-Benefits.affordable_connectivity p {
    max-width: 100%;
  }
  .Partner-Benefits p {
    max-width: 100%;
    text-align: center;
    margin-top: 17px;
  }
  .Partner-Benefits.ml-0 {
    margin-right: 0px;
  }
  .Partner-Benefits h5 {
    text-align: center;
  }
  .Partner-Benefits h4 {
    text-align: center;
  }
  .default-btn{
    max-width: 330px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .our-services {
    margin-top: 63px;
  }
  .Partner-Benefits.affordable_connectivity {
    order: 2;
    margin-left: 0;
  }
}
@media(max-width:767px){
  .services-benfits {
    height: 341px;
  }
}
@media (max-width:575px){
  .services-benfits {
    height: 300px;
  }
  .services-benfits .img-fluid {
    object-fit: contain;
  }
  .Partner-Benefits.affordable_connectivity {
    margin-top: 4px;
  }
  .our-services {
    margin-top: 43px;
  }
  .Partner-Benefits {
    margin-top: 23px;

  }
}