.faqs-section {
  position: relative;
  padding-bottom: 100px;
}
.faqs-content .accordion-item {
  position: relative;
}
.faqs-content .accordion-button::after {
  position: absolute;
  content: "";
  background-image: url(../../../../assets/images/plus.svg) !important;
  background-repeat: no-repeat;
  height: 72px !important;
  left: -30px;
  transition: unset !important;
  -webkit-transition: unset !important;
  -moz-transition: unset !important;
  -ms-transition: unset !important;
  -o-transition: unset !important;
  background-size: contain;
  top: 19px;
}
.faqs-content .accordion-button:not(.collapsed)::after {
  background-image: url(../../../../assets/images/minus.svg) !important;
  background-repeat: no-repeat;
  height: 49px !important;
  position: absolute;
  left: -30px;
  transition: unset !important;
  -webkit-transition: unset !important;
  -moz-transition: unset !important;
  -ms-transition: unset !important;
  -o-transition: unset !important;
  background-size: contain;
  top: 21px;
}
.faqs-content h3 {
  padding-bottom: 30px;
}
h2.accordion-header {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #070707;
  position: relative;
}
.faqs-content .accordion-body {
  border-radius: 0;
  border-left: 2px solid #1a2a79;
}
.faqs-content .accordion-button {
  padding: 0;
}
.faqs-content .accordion-button:not(.collapsed) {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #070707 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.faqs-content .accordion-button {
  font-size: 24px !important;
  font-weight: 600;
  color: #070707 !important;
  padding: 30px 0 !important;
  padding-left: 0;
}
.faqs-content .accordion-button:focus {
  z-index: 3;
  border-color: transparent !important;
  outline: 0;
  box-shadow: unset !important;
}
.faqs-content .accordion-body {
  margin-bottom: 22px !important;
}
.faqs-heading h4 {
  border-bottom: 3px solid;
  padding-bottom: 25px;
  width: 275px;
}
@media only screen and (max-width: 1600px) {
  .faqs-section {
    padding-bottom: 50px;
  }
  .accordion-body,
  .accordion-button:not(.collapsed),
  h2.accordion-header {
    font-size: 15px;
    line-height: 22px;
  }
  .faqs-content .accordion-button {
    font-size: 19px !important;
    padding-bottom: 20px !important;
  }
}
@media only screen and (max-width: 1600px) {
  .faqs-content .accordion-button {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 992px) {
  .faq-lottie > svg {
    display: none;
  }
  .faqs-content h3 {
    text-align: center;
  }
  .faqs-section::before {
    width: 117px;
    height: 430px;
    top: -150px;
    right: -12px;
  }
  .faqs-content .accordion-button {
    font-size: 14px !important;
    padding-bottom: 16px !important;
  }
  .faqs-content {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .faqs-heading {
    text-align: center;
  }
  .faqs-heading h4 {
    text-align: center;
    width: 100%;
  }
}
