.our-business-content h5 {
  color: #439037;
}
.our-business-content h4 {
  color: #1a2a79;
}
.our-business-content p {
  margin-top: 30px;
  max-width: 604px;
  width: 100%;
}
.our-business-wraper {
  display: flex;
}
.our-business-content {
  margin-left: 92px;
  margin-top: 110px;
}
.our-business-video video {
  border: 15px solid #fff;
  object-fit: cover;
  border-radius: 14px;
  box-shadow: 0 0 16px #0000001a;
  max-width: 821px;
  width: 100%;
  height: 523px;
  background-color: #fff;
}
.our-business {
  padding-top: 134px;
  position: relative;
  z-index: 9;
}
.Compensation-Plan h2,
.Compensation-Plan h3 {
  color: #1a2a79;
  text-align: center;
  line-height: 50px;
}
.Compensation-Plan h5 {
  color: #439037;
  text-align: center;
}
.Compensation-Plan {
  margin-top: 95px;
}
.our-business-video {
  position: relative;
}
.our-business {
  background-image: url(../../../../assets/images/people-background.png);
  background-position: bottom;
  width: 100%;
  background-size: 100% 62%;
  background-repeat: no-repeat;
}
.business-linear-background {
  background: transparent
    linear-gradient(224deg, #fff 0, #f7f7fe 36%, #f4f4fd 100%) 0 0 no-repeat
    padding-box;
}
.our-business::before {
  content: "";
  position: absolute;
  background-image: url(../../../../assets/images/sided-lines.png);
  width: 100%;
  width: 100%;
  height: 403px;
  background-repeat: no-repeat;
  z-index: -4;
  top: 33%;
  left: -24px;
}
.Compensation-Plan button {
  margin-top: 50px;
  margin-bottom: 143px;
}
.Compensation-Plan-btn {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.play-button-wrpaer {
  position: absolute;
  top: 35%;
  left: 39%;
}
@media (max-width: 1399px) {
  .our-business-video video {
    height: 373px;
  }
  .our-business::before {
    width: 45%;
    height: 275px;
    top: 35%;
    transform: rotate(168deg);
  }
  .Compensation-Plan button {
    margin-bottom: 84px;
  }
  .our-business {
    padding-top: 70px;
  }
  .our-business-content {
    margin-top: 63px;
  }
  .play-button-wrpaer {
    top: 26%;
    left: 34%;
  }
}
@media (max-width: 991px) {
  .our-business-wraper {
    flex-wrap: wrap;
    justify-content: center;
  }
  .our-business {
    background-size: contain;
  }
  .our-business-content {
    margin-left: 0;
  }
  .our-business::before {
    display: none;
  }
  .our-business-content {
    margin-top: 37px;
  }
  .our-business-content p {
    max-width: 100%;
    text-align: center;
  }
  .our-business-content h5 {
    text-align: center;
  }
  .our-business-content h4 {
    text-align: center;
  }
  .Compensation-Plan {
    margin-top: 50px;
  }
  .Compensation-Plan h2,
  .Compensation-Plan h3 {
    line-height: 40px;
  }
  .Compensation-Plan button {
    margin-bottom: 83px;
  }
}
@media (max-width: 767px) {
  .play-button-wrpaer {
    top: 29%;
    left: 35%;
  }
  .our-business {
    padding-top: 80px;
  }
}
@media (max-width: 575px) {
  .play-button-wrpaer {
    top: 29%;
    left: 38%;
  }
  .play-button-wrpaer lottie-player {
    width: 95px !important;
    height: 95px !important;
  }
  .Compensation-Plan h2,
  .Compensation-Plan h3 {
    line-height: 32px;
    font-size: 24px;
  }
  .first_member {
    min-height: 445px;
  }
  .our-business-video video {
    height: 323px;
  }
  .our-business {
    padding-top: 51px;
  }
  .our-business-content p {
    margin-top: 20px;
  }
  .Compensation-Plan {
    margin-top: 36px;
  }
  .Compensation-Plan button {
    margin-bottom: 48px;
    margin-top: 24px;
  }
}
@media (max-width: 480px) {
  .our-business-video video {
    height: 284px;
  }
  .Compensation-Plan h2,
  .Compensation-Plan h3 {
    line-height: 30px;
    font-size: 22px;
  }
}
