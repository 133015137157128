.compensate-pdf-text {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 0 20px #00000012;
  border-radius: 18px;
  padding: 45px;
  margin-top: 60px;
}
.compensate-accordian {
  margin-top: 35px;
}
.compensate-accordian .accordion-button:not(.collapsed) {
  color: #439037;
  background-color: transparent;
  box-shadow: unset;
}
.compensate-accordian .accordion-button {
  color: #1a2a79;
}
.compensate-accordian .accordion-button:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: unset;
}
.compensate-accordian .accordion-body {
  padding: 0;
}
.compensate-accordian h2.accordion-header {
  color: #1a2a79;
}
.compensate-accordian .accordion-header img {
  object-fit: contain;
  min-width: 50px;
}
.compensate-accordian table th {
  background-color: #439037 !important;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 18px;
  white-space: nowrap;
}
.compensate-accordian table tbody tr {
  border-width: 1px;
  color: #ffff;
}
.compensate-accordian table {
  border-color: #fff;
}
.compensate-accordian table td {
  font-size: 18px;
  color: #000;
  border-bottom: 1px solid #fff;
  font-weight: 400;
  padding: 10px 18px;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: #ededed57 !important;
}
.table-striped > tbody > tr:nth-of-type(even) > * {
  background-color: #e0e0e0 !important;
}
.compensate-accordian .table-striped > tbody > tr > th {
  background-color: #1b2a79 !important;
  font-weight: 400;
}
.logo-wraper-row {
  padding: 15px;
}
.logo-wraper-row .logo-img-wraper {
  max-width: 100%;
  height: 100px;
  padding: 5px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  margin: 0 auto 10px;
  box-shadow: 0 0 20px #00000012;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-wraper-row .logo-img-wraper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media only screen and (max-width: 1400px) {
  .logo-wraper-row .logo-img-wraper {
    height: 75px;
  }
  .compensate-accordian table td {
    font-size: 16px;
  }
  .compensate-accordian table th {
    font-size: 16px;
  }
  .compensate-pdf-text {
    margin-top: 45px;
  }
}
@media only screen and (max-width: 768px) {
  .compensate-accordian .accordion-header img {
    height: 30px;
  }
  .compensate-pdf-text {
    padding: 15px;
  }
  .compensate-accordian table td {
    font-size: 14px;
  }
  .compensate-accordian table th {
    font-size: 14px;
  }
  .logo-wraper-row .logo-img-wraper {
    height: 90px;
    max-width: 204px;
  }
  .compensate-pdf-text {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .compensate-accordian table td {
    font-size: 13px;
  }
  .compensate-accordian table th {
    font-size: 13px;
  }
  .compensate-accordian .accordion-header img {
    height: 22px;
  }
  .compensate-accordian {
    margin-top: 15px;
  }
}
