.site-header {
  width: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  border-top: 7px solid #409131;
}
.site-header .navbar {
  padding: 10px 0;
}
.site-header .navbar .pages-names a {
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0;
  font-weight: 600;
  color: #1a2a79;
  text-transform: capitalize;
  transition: all 0.5s;
  position: relative;
  margin: 0 0 0 50px;
  padding: 0;
  text-decoration: none;
}
.site-main-logo {
  width: 190px;
}
.call-lottie {
  width: 45px;
}
.haeder-phone {
  margin-left: 50px;
}
.site-header .navbar .pages-names a.active,
.site-header .navbar .pages-names a:hover {
  color: #439037 !important;
}
.site-header .navbar .pages-names a:after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: -4px;
  background: #439037;
  -o-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}
.site-header .navbar a.active::after,
.site-header .navbar a:hover::after {
  width: 100% !important;
}
@media only screen and (max-width: 1600px) {
  .site-header .navbar .pages-names a {
    margin: 0 0 0 30px;
    font-size: 16px;
  }
  .haeder-phone {
    margin-left: 30px;
  }
}
@media only screen and (max-width: 1399px) {
  .haeder-phone {
    margin-left: 20px;
  }
  .site-header .navbar .pages-names a {
    margin: 0 0 0 20px;
    font-size: 14px;
  }
  .header-blue-btn.btn_wrap button {
    margin: 0 0 0 20px;
  }
  .site-header .navbar {
    padding: 10px 0;
  }
  .site-main-logo {
    width: 153px;
  }
}
@media only screen and (max-width: 1199px) {
  .site-header .navbar .pages-names a {
    margin: 0 0 0 15px;
    font-size: 13px;
  }
  .logo-image {
    width: 200px;
  }
  .site-header.navbar .nav-link {
    font-size: 13px;
  }
}
@media only screen and (max-width: 991px) {
  .header-blue-btn.btn_wrap button {
    margin-right: 60px;
    margin-left: 0;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgb(72, 177, 56)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
  }
  .site-header .navbar-toggler {
    border: 1px solid;
    color: #fff;
    box-shadow: none !important;
    outline: 0;
    position: absolute;
    right: 23px;
    z-index: 10;
  }
  .site-header .navbar-collapse {
    position: absolute;
    top: 90px;
    width: 100%;
    background: #1a2a79;
    width: 100%;
    margin: 0 auto;
    padding: 10px 10px;
    left: 0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  .site-header .navbar a.active,
  .site-header .navbar a:hover {
    color: #439037 !important;
  }
  .site-header .navbar .pages-names a {
    margin: 0;
    padding-top: 15px;
    font-size: 16px;
    color: #fff !important;
  }
  .site-header .navbar .pages-names a.active,
  .site-header .navbar .pages-names a:hover {
    color: #439037 !important;
  }
  .site-header .navbar {
    padding: 10px 0;
  }
  .site-header .navbar-collapse {
    top: 70px;
  }
}
@media only screen and (max-width: 992px) {
  .haeder-phone {
    margin-left: 0;
  }
}
@media only screen and (max-width: 567px) {
  .site-main-logo {
    width: 145px;
  }
  .logo-image {
    width: 150px;
  }
  .btn_wrap span {
    padding: 12px 15px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 450px) {
  .btn_wrap span {
    padding: 10px 10px;
    font-size: 12px;
  }
  .header-blue-btn.btn_wrap button {
    margin-right: 70px;
  }
  .site-header .navbar-toggler {
    right: 16px;
  }
}
